<template>
<!-- :style="{'background': coreview}" -->
  <v-main class="coreview">
    <v-img
      :max-height="cardHeight / 5"
      src="@/assets/dara_pic5.svg"
      :aspect-ratio="16/9"
      gradient="180deg, rgba(0,0,0,0.2969674930519083) 0%, rgba(34,34,59,1) 100%"
    >
      <!-- src="@/assets/building1.jpg" -->
      <v-row
        class="mt-10"
        align="center"
        justify="center"
      >
        <v-col
          class="text-center white--text text--lighten-1 mt-5"
          cols="12"
        >
          <h1 class="display-1 font-weight-light">
            {{ $route.name }}
            <!-- {{ $vuetify.breakpoint.name }}
            DARA -->
          </h1>
          <!-- <h4 class="subheading font-weight-light">
            Develop Environment
          </h4> -->
        </v-col>
      </v-row>
    </v-img>
    <v-layout
      class="mb-6"
      justify-center
    >
      <v-card
        class="py-6"
        width="95vw"
        :elevation="12"
        min-height="70vh"
      >
        <!-- :style="{'margin-top': '-800px'}" -->
        <v-layout
          fill-height
          justify-center
        >
          <v-card
            flat
            :width="sheetWidth"
          >
            <router-view></router-view>
          </v-card>
        </v-layout>
      </v-card>
    </v-layout>
  </v-main>
</template>

<script>
export default {
  name: 'CoreView',
  components: {
  },
  data: () => ({
    loading: false,
    selection: 1,
    colors: [
      'indigo',
      'warning',
      'pink darken-2',
      'red lighten-1',
      'deep-purple accent-4'
    ],
    slides: [
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth'
    ]
  }),
  methods: {
    reserve () {
      this.loading = true
      setTimeout(() => (this.loading = false), 1000)
    }
  },
  computed: {
    sheetWidth: function () {
      switch (this.$vuetify.breakpoint.name) {
        case 'sm':
          return '90%'
        case 'md':
          return '80%'
        case 'lg':
          return '70%'
        default:
          return '60%'
      }
    },
    cardHeight: function () {
      return this.$vuetify.breakpoint.height - 50
    },
    currentRouteName () {
      return this.$route.name
    }
  }
}
</script>
